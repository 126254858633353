import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { population, reports } from '../../../functions/Users'
import '../../../assets/vendors4/jquery-toggles/css/toggles.css'
import '../../../assets/vendors4/jquery-toggles/css/themes/toggles-light.css'
import '../../../assets/dist/css/style.css'
import { HorizontalBar } from 'react-chartjs-2';
import  exportCSV from '../../../functions/ExportToCSV';
import GenericError from '../errorviews/GenericError'

/**
* @author
* @class ByPopulation
**/
var inactiveusersArray = [];

const exportButton = {
    float: "right",
    backgroundColor: "#22af47",
    padding: "8px",
    border: "#22af47 2px solid",
    borderRadius: "5px",
    color: "white"
}


const headings = {
    padding: "15%, 2%",
    justifyContent: "space-between"
}

class ByPopulation extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            active: {},
            noData: null
        };

        this.exportReports = this.exportReports.bind(this);
    }

    exportReports() {
        reports().then(res => {
            //console.log(res.members);
            exportCSV(Object.values(res.members));
        })
        .catch(err =>{
            console.log(err);
        });
    }


    componentDidMount() { 
        population().then(res => {
            if(res) {
                inactiveusersArray = res.members;
                const territories = res.members.map(t => t.territory);
                const population = res.members.map(p => p.population);

                this.setState({
                    active: {
                        type: 'bar',
                        labels: territories,
                        datasets: [{
                            label: 'Population by territory',
                            data: population,
                            backgroundColor: 'rgb(105, 201, 130, 1)'
                        }]
                    }
                })
            }else {
                this.setState({noData: true});
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    
 render() {
    if (this.state.noData) {
        return (
            <GenericError />
        )
    }
    return (
        <React.Fragment>
            <div className="hk-wrapper hk-vertical-nav" style={{ marginTop: "120px", marginLeft: "240px" }}>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="hk-pg-header">
                        <h4 className="hk-pg-title"><span className="pg-title-icon"><span className="feather-icon"><i data-feather="share-2"></i></span></span>Members population by territory</h4>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                          
                        <section className="hk-sec-wrapper">
                                    <div className="row" style={headings}>
                                        <button onClick={this.exportReports} style={exportButton}>Export as CSV</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div id="m_chart_2" className="" style={{ height: "594px" }}>
                                                <HorizontalBar
                                                    data={this.state.active}
                                                    width={100}
                                                    height={50}
                                                    options={{
                                                        maintainAspectRatio: false
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </section>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 }


ByPopulation.propTypes = {}
export default ByPopulation