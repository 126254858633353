import React, { Component } from 'react'
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { disability, disabledmembers } from '../../../functions/Users';
import exportCSV from '../../../functions/ExportToCSV';

/**
* @author
* @class DisabilityReports
**/
const exportButtonStyle = {
    marginLeft: "20%",

}

const exportItemStyle = {
    borderBottom: "1px solid #ccc",
    padding: "1%"
}

class DisabilityReports extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            terry: []
        }

        this.exportReports = this.exportReports.bind(this);
    }

    async componentDidMount() {
        disability()
            .then(res => {
                const territory = res.members.map(obj => obj.territory);
                const population = res.members.map(obj => obj.population);

                this.setState({
                    terry: territory
                })

                this.setState({
                    disability: {
                        type: 'bar',
                        labels: territory,
                        datasets: [{
                            label: 'Disability Status',
                            data: population,
                            backgroundColor: 'rgb(105, 201, 130, 1)'
                        }]
                    }
                })
            })
            .catch(err => {
                console.log(err);
            })
    }


    exportReports(e) {
        //console.log(e.target.id);
        const userTerritory = {
            territory: e.target.id
        }

        disabledmembers(userTerritory)
            .then(res => {
                //console.log(res.members.map(obj => Object.values(obj)));
                exportCSV(Object.values(res.members));
            })
            .catch(err => {
                console.log(err);
            });

    }


    render() {
        return (
            <React.Fragment>
                <div className="hk-wrapper hk-vertical-nav" style={{ marginTop: "120px", marginLeft: "240px" }}>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="hk-pg-header">
                            <h4 className="hk-pg-title"><span className="pg-title-icon"><span className="feather-icon"><i data-feather="share-2"></i></span></span>Disability Reports</h4>

                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <section className="hk-sec-wrapper">
                                    <div className="row">
                                        <div className="col-sm">
                                            <div id="m_chart_2" className="" style={{ height: "594px" }}>
                                                <Bar
                                                    data={this.state.disability}
                                                    width={100}
                                                    height={50}
                                                    options={{
                                                        maintainAspectRatio: false
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className="hk-sec-wrapper">
                                    <h2 style={{marginBottom: "1%"}}><u>GENERATE REPORTS</u></h2>
                                    <table id="datable_1"
                                        className="table w-100 display pb-30 dataTable dtr-inline"
                                        role="grid" aria-describedby="datable_1_info">
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting" tabindex="0" aria-controls="datable_1"
                                                    rowspan="1" colSpan="1" aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending">Territory
                                                    </th>
                                                <th className="sorting" tabindex="0" aria-controls="datable_1"
                                                    rowspan="1" colSpan="1"
                                                    aria-label="Trade: activate to sort column ascending">
                                                    Export</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.terry.map((item, index) => {
                                                return <tr role="row" class="odd" >
                                                    <td key={index}>{item}</td>
                                                    <td><button className="btn btn-success btn-md" onClick={this.exportReports} id={item} >Export</button></td>
                                                </tr>
                                            })}
                                        </tbody>
                                        <tfoot>

                                        </tfoot>
                                    </table>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


DisabilityReports.propTypes = {}
export default DisabilityReports
