import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { user } from '../../../functions/Users';
import swal from 'sweetalert';
import { deletemember } from '../../../functions/Users';
import PrintJob from 'print-job';
import './printStyles.css';
import logo from '../../../assets/dist/img/transparent_logo.png'
import barcode from '../../../assets/dist/img/barcode.png'
import background from '../../../assets/dist/img/gz.jpg'

const IMAGES_URL = "https://api.zciea.trade/uploads/";
//const IMAGES_URL = "http://localhost:5000/uploads/";

const divStyle = {
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: '24px'
  };

  const avatar = {
      border: '2px solid green',
      width:'150px',
      height: '150px', 
  }

  const logoStyles = {
      width: '35%',
      height: '35%'  }

  const cardStyle = {
      margin: '10px',
      padding: '10px',
      position: 'relative',
      border: '1px solid darkgreen',
     // width: '325px',
      //height: '204px'
  }

  const flexStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '5px'
  }



class UserID extends Component {
    // constructor(props, ...rest) {
    //     super(props, ...rest);
        state = {
            name: '',
            nationalid: '',
            idnumber: '',
            gender: '',
            territory: '',
            gender: '',
            profile: '', 
            validity: ''
        };

        imageNotFound = this.imageNotFound.bind(this);
        printID = this.printID.bind(this);
        onDeleteMember = this.onDeleteMember.bind(this);

 //   }


     componentWillMount =  () => {
        var querystr = this.props.location.search;
        var idstr = querystr.split("=")[1];
        const u = { userid: idstr }

        user(u).then(res => {
            if (res.error) {
                console.log("something went wrong");
            } else {
               // console.log("RES: "+JSON.stringify(res) );
               var d = new Date();
               var pastYear = d.getFullYear() + 5;
               d.setFullYear(pastYear);

                let userDetails = res.results[0];
                console.log(userDetails);

                this.setState({
                    name: userDetails.name,
                    gender: userDetails.gender,
                    territory: userDetails.territory,
                    profile: userDetails.image,
                    nationalid: userDetails.id_number,
                    validity: new Date().getFullYear() +" - " + pastYear
                })
                console.log(IMAGES_URL+ this.state.profile);

                var text = "";
                var possible = "0123456789";

              
                for (var i = 0; i < 5; i++) 
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                    console.log(text);
                    this.setState({idnumber: "ZC_"+new Date().getFullYear()+"_"+new Date().getMonth()+text})

            }
        })
    }

    imageNotFound() {
        this.setState({
            profile: 'placeholder.png'
        })
    }


    printID() {
       PrintJob.print("#print");
    }


    onDeleteMember() {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to undo this action",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {

            
            if (willDelete) {
                const member = {
                    id_number: this.state.idnumber
                }

                deletemember(member).then(res => {
                    console.log(res);
                    
                })

              swal("Member has been deleted!", {
                icon: "success",
              });

              this.props.history.push('/view-members') 
            } 
          });
    }


    render() {
        return (
            <React.Fragment>
                <div className="hk-wrapper hk-vertical-nav" style={{ marginTop: "5%", marginLeft: "240px" }}>
                    <div className="hk-wrapper"></div>
                    <div className="container-fluid px-xxl-65 px-xl-20">
                        <div className="hk-pg-header">
                            <h4 className="hk-pg-title"><span className="pg-title-icon"><span className="feather-icon"><i data-feather="layers"></i></span></span></h4>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <section className="hk-sec-wrapper">
                                    <h5 className="hk-sec-title">ZCIEA Membership ID</h5>

                                    <div className="container">
                                        <div className="id-card col-md-8" id="print" style={cardStyle} >
                                            <div style={flexStyles}>
                                                <span><img src={logo} style={{ width:"30%",  height:"30%"}}alt="user" style={{width:"90px", }} onError={this.imageNotFound} /></span>
                                                <span style={{fontSize: "24px", fontWeight: "700", textAlign: "center"}}>ZIMBABWE CHAMBER OF INFORMAL <br/> ECONOMY ASSOCIATIONS</span>
                                                <span></span>
                                            </div>
                                            <div style={flexStyles}>
                                                <span>
                                                <div><img src={IMAGES_URL+this.state.profile} style={{ width:"50%",  height:"50%"}}alt="user" style={avatar} onError={this.imageNotFound} /></div>
                                                </span>
                                                <span>
                                                <div>
                                                    <div style={divStyle}>
                                                                    <p>NAME:&nbsp;&nbsp;</p>
                                                                    <p> {this.state.name}</p>
                                                                </div>

                                                                <div style={divStyle}>
                                                                    <p className="text-">NATIONAL ID :&nbsp;&nbsp;</p>
                                                                    <p> {this.state.nationalid} </p>
                                                                </div>

                                                                <div style={divStyle}>
                                                                    <p className="text-">TERRITORY:&nbsp;&nbsp;</p>
                                                                    <p> {this.state.territory}</p> 
                                                                </div>

                                                                <div style={divStyle}>
                                                                    <p className="text-">GENDER: &nbsp;&nbsp;</p>
                                                                    <p>{this.state.gender}</p> 
                                                                </div>

                                                                <div style={divStyle}>
                                                                    <p className="text-">VALIDITY: &nbsp;&nbsp;</p>
                                                                    <p>{this.state.validity}</p> 
                                                                </div>

                                                                <div style={divStyle}>
                                                                    <p className="text-">MEMBER ID: &nbsp;&nbsp;</p>
                                                                    <p>{this.state.idnumber}</p> 
                                                                </div>
                                                    </div>
                                                </span>
                                                <span><img src={barcode} alt="user"  onError={this.imageNotFound} /></span>
                                               
                                            </div> 
                                            <div><p style={{textAlign:"center", fontStyle:"italic", fontWeight:"bold"}}>“The Chamber That Delivers”</p></div>
                                        </div>
                                        
                                    </div>
                                    
                                    <button className="btn btn-success" style={{ textAlign: "center" }} onClick={this.printID}>Print ID</button>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

UserID.propTypes = {};
export default withRouter(UserID)
