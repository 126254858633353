import React, { Component } from 'react'
import { HorizontalBar } from 'react-chartjs-2';
import { trades, bytrade } from '../../../functions/Users';
import exportCSV from '../../../functions/ExportToCSV';
/**
* @author
* @class ByTrade
**/
const exportButton = {
    float: "right",
    backgroundColor: "#22af47",
    padding: "8px",
    border: "#22af47 2px solid",
    borderRadius: "5px",
    color: "white"
}


const headings = {
    padding: "15%, 2%",
    justifyContent: "space-between"
}
const exportButtonStyle = {
    marginLeft: "20%",

}

const exportItemStyle = {
    borderBottom: "1px solid #ccc",
    padding: "1%"
}

class ByTrade extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            trde: []
        }

        this.exportReports = this.exportReports.bind(this);
    }


    componentDidMount() {
        trades().then(res => {
            if (res) {
                const trade = res.members.map(t => t.trade);
                const number = res.members.map(p => p.number);

                console.log(res.members);
                this.setState({
                    trde: trade
                })

                this.setState({
                    active: {
                        type: 'bar',
                        labels: trades,
                        datasets: [{
                            label: 'Population by territory',
                            data: number,
                            backgroundColor: 'rgb(105, 201, 130, 1)'
                        }],
                        xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 2
                            }
                        }]
                    }
                })
            } else {
                this.setState({ noData: true });
            }
        })
            .catch(err => {
                console.log(err);
            });
    }


    exportReports(e) {
        const usertrade = {
            trade: e.target.id
        }

        bytrade(usertrade)
            .then(res => {
                //console.log(res.members.map(obj => Object.values(obj)));
                exportCSV(Object.values(res.members))
            })
            .catch(err => {
                console.log(err);
            })

    }

    render() {
        return (
            <React.Fragment>
                <div className="hk-wrapper hk-vertical-nav" style={{ marginTop: "120px", marginLeft: "240px" }}>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="hk-pg-header">
                            <h4 className="hk-pg-title"><span className="pg-title-icon"><span className="feather-icon"><i data-feather="share-2"></i></span></span>Member Trade Information</h4>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <section className="hk-sec-wrapper">
                                    <div className="row" style={headings}>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div id="m_chart_2" className="" style={{ height: "594px" }}>
                                                <HorizontalBar
                                                    data={this.state.active}
                                                    width={10}
                                                    height={50}
                                                    options={{
                                                        maintainAspectRatio: false
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className="hk-sec-wrapper">
                                    <table id="datable_1"
                                        className="table w-100 display pb-30 dataTable dtr-inline"
                                        role="grid" aria-describedby="datable_1_info">
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting" tabindex="0" aria-controls="datable_1"
                                                    rowspan="1" colSpan="1" aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending">Trade
                                                    </th>
                                                <th className="sorting" tabindex="0" aria-controls="datable_1"
                                                    rowspan="1" colSpan="1"
                                                    aria-label="Trade: activate to sort column ascending">
                                                    Export</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.trde.map((item, index) => {
                                        return <tr role="row" class="odd" >
                                                    <td key={index}>{item}</td>
                                                    <td><button className="btn btn-success btn-md" onClick={this.exportReports} id={item} >Export</button></td>
                                                </tr>
                                            })}
                                        </tbody>
                                        <tfoot>
                                       
                                        </tfoot>
                                    </table>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


ByTrade.propTypes = {}
export default ByTrade